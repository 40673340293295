import React from 'react';
import styled from 'styled-components';
import { Heading } from '@atoms';

const QuoteBlockArticle = ({ quoteData }) => {
  const { quote, author, type } = quoteData;
  return (
    <CompWrapper
      data-comp={QuoteBlockArticle.displayName}
    >
      <QuotationGraphic>"</QuotationGraphic>
      <Heading 
        headingSize='H5'
        style={{
          color: '#002DC2',
          fontWeight: 700
        }}
      >
        {quote?.quote}
      </Heading>
    </CompWrapper>
  );
};

QuoteBlockArticle.displayName = 'QuoteBlockArticle';
export default QuoteBlockArticle

const CompWrapper = styled.div`
  width: 100%;
`

const QuotationGraphic = styled.div`
  font-family: News Cycle;
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  color: #69D1F0;
  @media (max-width: 576px) {
    font-size: 72px;
  }
`

